import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/orders';
import { getOrder, getOrders, getByPagened, getByOrderNumber, getAllYear, getByYear, getByDayByDay, getByProvider, updateOrder, getFtpOrders } from './order';

const orderSagas = [
	takeEvery(Types.FETCH_ORDER, getOrder),
	takeEvery(Types.FETCH_ORDERS_PAGENED, getByPagened),
	takeEvery(Types.FETCH_ORDERS, getOrders),
	takeEvery(Types.FETCH_ALL_YEAR, getAllYear),
	takeEvery(Types.FETCH_BY_YEAR, getByYear),
	takeEvery(Types.FETCH_BY_DAY_BY_DAY, getByDayByDay),
	takeEvery(Types.DOWNLOAD_FTP_ORDERS, getFtpOrders),
	takeEvery(Types.FETCH_BY_ORDER_NUMBER, getByOrderNumber),
	takeEvery(Types.FETCH_BY_ORDER_PROVIDER, getByProvider),
	takeEvery(Types.PUT_ORDER, updateOrder)
]

export default orderSagas;