import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

export const CollapseItem = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  background: #f5f5f5;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    background: #e0e0e0;
  }
`;

export const Details = styled.div`
  padding: 1rem;
  background: #fff;

  .card {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #fafafa;
  }

  .card p {
    margin: 0.5rem 0;
  }

  .label {
    font-weight: bold;
    color: #333;
  }

  .value {
    margin-left: 0.5rem;
    color: #555;
  }
`;
