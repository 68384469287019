import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/bulk';

export function* sendBulk({ company, fileBase64, status, message, code_response }) {
  try {
    const data = { company, fileBase64, status, message, code_response, job_type: 1, job_status: 2};
    const response = yield call(api.post, '/v1/bulk', data);
    
    const {body} = response.data;
    
    yield put(Creators.fetchBulkSuccess(body));
  } catch (e) {
    console.error('ERROR HERE');
    const { response, message } = e;
    console.error(response);
    yield put(
      Creators.fetchBulkFailure({
        status: response?.status,
        message: 'Erro ao tentar criar a fila! Tente novamente.'
      }),
    );
  }
}
