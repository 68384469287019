/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Box, Card, CardContent, 
  Grid, Paper, Typography,
} from "@material-ui/core";
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Logo from 'assets/logo.png';
import clsx from 'clsx';
import ColoredLinearProgress from 'pages/components/LineProgress';

import Home from 'pages/home';
import ChartAllYear from './ChartAllYear';
import ChartByYear from './ChartByYear';
import ChartByDayByDay from './ChartByDayByDay';

// Actions
import { Creators } from 'store/ducks/orders';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fixedHeight: {
    minheight: 240,
  },
}));

const Index = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const { user } = useSelector(state => state.signin);
  const { pending, reportAllYear, reportByYear, reportByDayByDay } = useSelector(state => state.orders);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  
  const [nowTotal, setNowTotal] = useState(0);
  const [yearTotal, setYearTotal] = useState(0);

  useEffect(() => {
    dispatch(Creators.fetchByDayByDay());
    dispatch(Creators.fetchAllYear());
    dispatch(Creators.fetchByYear(new Date().getFullYear()));
  },[]);

  useEffect(() => {
    if(reportByDayByDay && reportByDayByDay.data){
      const item = [...reportByDayByDay?.data].find(i => i.date === moment().format('DD/MM/YYYY'));
      setNowTotal(item?.total);
    }
  },[reportByDayByDay]);

  useEffect(() => {
    if(reportAllYear && reportAllYear.data){
      const item = [...reportAllYear?.data].find(i => i.date === moment().format('YYYY'));
      setYearTotal(item?.total);
    }
  },[reportAllYear]);

  return (
    <Home title={'Dashboard'}>
      {pending && <ColoredLinearProgress />}
      <Paper className={fixedHeightPaper}>
      
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <img src={Logo} style={{width: 200, alignContent: 'center'}} />
          </Grid>
        </Grid>

        <div style={{height: 20}} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Card sx={{ display: 'flex' }} variant='outlined'>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="subtitle1" color="textSecondary" component="div">
                    Total Geral
                  </Typography>
                  <Typography component="div" variant="h3">
                    {reportAllYear?.total || 0}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ display: 'flex' }} variant='outlined'>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="subtitle1" color="textSecondary" component="div">
                    Este Ano
                  </Typography>
                  <Typography component="div" variant="h3">
                    { yearTotal || 0}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ display: 'flex' }} variant='outlined'>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="subtitle1" color="textSecondary" component="div">
                    Mês Atual
                  </Typography>
                  <Typography component="div" variant="h3">
                    { reportByYear?.total || 0}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ display: 'flex' }} variant='outlined'>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="subtitle1" color="textSecondary" component="div">
                    Hoje
                  </Typography>
                  <Typography component="div" variant="h3">
                    { nowTotal || 0}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <div style={{height: 20}} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ChartAllYear title={'Crescimento por Ano'} data={reportAllYear.data}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartByYear title={'Crescimento por Mês'} data={reportByYear.data}/>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <ChartByDayByDay title={'Crescimento dia-a-dia'} data={reportByDayByDay.data}/>
          </Grid>
        </Grid>
      </Paper>
    </Home>
  );
}

export default Index;