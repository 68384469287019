import { all } from 'redux-saga/effects';
import orderSagas from './order';
import bulkSagas from './bulk';
import signinSagas from './signin';
import usersSagas from './users';
import validateSagas from './validate';
import clientsSagas from './clients';
import trackingSagas from './tracking';
import cepsSagas from './ceps';

export default function* rootSaga() {
	yield all([
		...signinSagas,
		...orderSagas,
		...bulkSagas,
		...usersSagas,
		...validateSagas,
		...clientsSagas,
		...trackingSagas,
		...cepsSagas,
	]);
}
