import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/ceps';
import { fetchCeps } from './ceps';

const cepsSagas = [
	takeEvery(Types.FETCH_CEPS, fetchCeps),
  takeEvery(Types.FETCH_CEPS_SUCCESS, () => {
    // Navigation.navigate('Main');
  }),
  takeEvery(Types.FETCH_CEPS_FAILURE, () => {
  })
]

export default cepsSagas;