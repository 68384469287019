import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/ceps';

export function* fetchCeps({ zip, radius }) {
  try {
    const response = yield call(api.post, '/v2/ceps/search', {zip, radius});
    
    const {body} = response.data;
    console.log('body');
    console.log(body);
    
    yield put(Creators.fetchCepsSuccess(body));
  } catch (e) {
    console.error('ERROR HERE');
    const { response, message } = e;
    console.error(response);
    yield put(
      Creators.fetchCepsFailure({
        status: response?.status,
        message: 'Erro ao tentar buscar a lista de CEP! Tente novamente.'
      }),
    );
  }
}
