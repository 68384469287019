import { Link, Typography } from "@material-ui/core";
import React from 'react';

const Index = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://venuxxwebservices.com">
      Venuxx
      </Link>
      {` 2001-${new Date().getFullYear()}`}
      {` - versão 2.6.1`}
    </Typography>
  );
}

export default Index
