import React, { useState } from 'react';
import { Container, CollapseItem, Header, Details } from './styles';
import { Button, Grid, Paper, Typography, TextField, Modal } from "@material-ui/core";
import moment from "moment-timezone";

const labelsMap = [
  { label: "ID", key: "id" },
  { label: "Provider", key: "provider" },
  { label: "Status", key: "status" },
  { label: "Motivo", key: "motive" },
  { label: "Código Resposta", key: "code_response" },
  { label: "Mensagem Resposta", key: "message_response" },
  { label: "Data de envio", key: "shipped_date" },
  { label: "Nº Ordem", key: "order_number" },
  { label: "Cod. Rastreio", key: "tracking_code" },
  { label: "Itens", key: "items_quantity" },
  { label: "NF Série", key: "invoice_series" },
  { label: "NF Número", key: "invoice_number" },
  { label: "NF Chave", key: "invoice_key" },
  { label: "Cubagem", key: "weight" },
  { label: "Tipo encomenda", key: "shipment_order_type" },
  { label: "Ref", key: "shipping_reference" },
  { label: "Nome", key: "first_name" },
  { label: "Sobrenome", key: "last_name" },
  { label: "Telefone", key: "phone" },
  { label: "Celular", key: "cellphone" },
  { label: "E-mail", key: "email" },
  { label: "Endereço", key: "shipping_address" },
  { label: "Número", key: "shipping_number" },
  { label: "Complemento", key: "shipping_additional" },
  { label: "Bairro", key: "shipping_quarter" },
  { label: "Cidade", key: "shipping_city" },
  { label: "Estado", key: "shipping_state" },
  { label: "País", key: "shipping_country" },
  { label: "CEP", key: "shipping_zip_code" },
  { label: "Enviado por", key: "origin_name" },
  { label: "CNPJ", key: "sender_cnpj" },
  { label: "Telefone", key: "origin_customer_phone" },
  { label: "Endereço", key: "origin_street" },
  { label: "Número", key: "origin_number" },
  { label: "Complemento", key: "origin_additional" },
  { label: "Ref", key: "origin_reference" },
  { label: "Bairro", key: "origin_quarter" },
  { label: "Cidade", key: "origin_city" },
  { label: "Estado", key: "origin_state_code" },
  { label: "CEP", key: "origin_zip_code" },
  { label: "Premium?", key: "is_premium" },
  { label: "Expedidor CNPJ", key: "cnpj_expeditor" },
  { label: "Criado em", key: "created_at" },
];

const InvoiceCollapse = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleCollapse = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const getLabel = (key) => {
    const labelObj = labelsMap.find((item) => item.key === key);
    return labelObj ? labelObj.label : key;
  };

  return (
    <Container>
      {data.map((invoice, index) => (
        <CollapseItem key={invoice.id}>
          <Header onClick={() => toggleCollapse(index)}>
            <div>
              <strong>Ordem:</strong> <span style={{fontSize:'1rem', fontWeight:'bold', color:'darkmagenta'}}>{invoice.order_number || ""}</span> <strong>NF:</strong> {invoice.invoice_number || ""} <strong>Provider:</strong> <span style={{color:'darkorange', fontWeight:'bold'}} >{String(invoice.provider).toUpperCase() || ""}</span> <strong>Status:</strong> <span style={{backgroundColor:'#f7f7f7', padding:10, borderRadius:10, textAlign:'center', fontWeight:'bold', color:'blue'}} >{String(`${invoice?.message_response || invoice?.motivo || 'PEDIDO RECEBIDO'}`).toUpperCase()}</span> <strong>Criado em:</strong> <span style={{color:'green', fontWeight:'bold'}} >{moment(invoice.created_at).format("DD/MM/YYYY HH:mm:ss") || ""}</span>
            </div>
            <div>
              <strong>Cliente:</strong> {`${invoice.first_name} ${invoice.last_name}`} <strong>Endereço:</strong> {invoice.shipping_address || ""}, {invoice.shipping_number || ""} - {invoice.shipping_city || ""}
            </div>
          </Header>
          {activeIndex === index && (
            <Details>
              <div style={{border:'1px solid #808080',  borderRadius:10, margin:5 ,padding:10}}>
                <Typography variant='h4' style={{textAlign:'center'}}>Dados da Nota</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div><strong>Provider:</strong></div>
                        <div style={{backgroundColor:'#f7f7f7', padding:10, borderRadius:10, fontWeight:'bold', color:'darkorange'}} >{String(invoice?.provider).toUpperCase()}</div>
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Número da Ordem:</strong></div> 
                        <div style={{fontSize:'1rem', fontWeight:'bold', color:'darkmagenta'}}>{invoice?.order_number}</div>
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Data da Emissão:</strong></div> {moment(invoice.created_at).format("DD/MM/YYYY HH:mm:ss") || ""}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div><strong>Premium?:</strong></div> {invoice?.is_premium ? 'SIM' : 'NÃO'}
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Tipo da Ordem:</strong></div> {invoice?.shipment_order_type}
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Número do Pacote:</strong></div> {invoice?.packing_number || "-"}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div><strong>Status:</strong></div> 
                        <div style={{backgroundColor:'#f7f7f7', padding:10, borderRadius:10, textAlign:'center', fontWeight:'bold', color:'blue'}} >{invoice?.message_response || invoice?.motivo || 'PEDIDO RECEBIDO'}</div>
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Atualizado em:</strong></div> {moment(invoice.updated_at).format("DD/MM/YYYY HH:mm:ss") || ""}
                      </Grid>
                      <Grid item xs={12}>
                        <div><strong>Número do Rastreio:</strong></div> {invoice?.tracking_code}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <div><strong>Série da Nota:</strong></div> {invoice.invoice_series || ""}
                      </Grid>
                      <Grid item xs={4}>
                        <div><strong>Número da Nota:</strong></div> {invoice.invoice_number || ""}
                      </Grid>
                      <Grid item xs={6}>
                        <div><strong>Chave da Nota:</strong></div> {invoice?.invoice_key}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <div><strong>Data da Nota:</strong></div> {invoice.invoice_date ? moment(invoice.invoice_date).format("DD/MM/YYYY HH:mm:ss") : ""}
                      </Grid>
                      <Grid item xs={4}>
                        <div><strong>Total da Nota:</strong></div> R$ {invoice.invoice_total_value ? Number(invoice.invoice_total_value).toFixed(2) : "0,00"}
                      </Grid>
                      <Grid item xs={6}>
                        <div><strong>Quantidade de Itens:</strong></div> {invoice?.volumes_quantity}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div style={{border:'1px solid #808080',  borderRadius:10, margin:5 ,padding:10}}>
                <Typography variant='h4' style={{textAlign:'center'}}>Prestador de Serviços</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <strong>CNPJ:</strong> {invoice?.cnpj_expeditor}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <strong>Criado em:</strong> {invoice?.created_at}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <strong>Nome/Razão Social:</strong> {String(invoice?.provider).toUpperCase()} - {invoice?.origin_name}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <strong>Endereço:</strong> {invoice?.origin_street}, {invoice?.origin_number} - {invoice?.origin_additional}, {invoice?.origin_quarter}, <strong>CEP:</strong> {invoice?.origin_zip_code}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <strong>Município:</strong> {invoice?.origin_city}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <strong>UF:</strong> {String(invoice?.origin_state_code).toUpperCase()}
                  </Grid>
                </Grid>
              </div>

              <div style={{border:'1px solid #808080',  borderRadius:10, margin:5 ,padding:10}}>
                <Typography variant='h4' style={{textAlign:'center'}}>Tomador de Serviços</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <strong>Nome/Razão Social:</strong> <span style={{color:'green', fontWeight:'bold'}}>{String(`${invoice?.first_name} ${invoice?.last_name}`).toUpperCase()}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <strong>Endereço:</strong> <span style={{color:'blue', fontWeight:'bold'}}>{invoice?.shipping_address}, {invoice?.shipping_number} - {invoice?.shipping_additional}, {invoice?.shipping_quarter}, <strong>CEP:</strong> {invoice?.shipping_zip_code}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <strong>Município:</strong> {invoice?.shipping_city}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <strong>UF:</strong> {String(invoice?.shipping_state).toUpperCase()}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <strong>Celular:</strong> {invoice?.cellphone}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <strong>Telefone:</strong> {invoice?.phone}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <strong>E-Mail:</strong> {invoice?.email}
                  </Grid>
                </Grid>
              </div>
            </Details>
          )}
        </CollapseItem>
      ))}
    </Container>
  );
};

export default InvoiceCollapse;
