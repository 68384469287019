import Title from 'pages/components/Title';
import * as React from 'react';
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

export default function Chart(props) {

  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <ResponsiveContainer width={'99%'} maxHeight={400} style={{borderLeft: '2px solid #000'}}>
        <ComposedChart
          width={500}
          height={400}
          data={props.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="total" fill="#8884d8" stroke="#8884d8" />
          <Bar dataKey="total" barSize={20} fill="#413ea0" />
          
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
