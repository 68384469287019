import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Paper, TextField, Button, InputLabel} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";
import Alert from '@material-ui/lab/Alert';
import { CSVLink } from "react-csv";

import Home from 'pages/home';

import ColoredLinearProgress from 'pages/components/LineProgress';

// Actions
import { Creators } from 'store/ducks/ceps';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  labelError: {
    color: 'red',
    fontSize: 14
  },
  btnExportCSV:{
    border: '1px solid #f50057',
    borderRadius: 5,
    padding: 8,
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
    display: 'inline-block',
    backgroundColor: '#f50057',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
  }

}));

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending, data, error } = useSelector(state => state.ceps);

  const [zip, setZip] = useState("");
  const [zipError, setZipError] = useState("");
  const [radius, setRadius] = useState("1");
  const [radiusError, setRadiusError] = useState("");

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    if(zipError || radiusError){
      setZipError("");
      setRadiusError("");
    }
  }, [zip, radius]);

  const handlerSeek = async () => {
    if(!zip || (zip.length < 8 || zip.length > 8)){
      setZipError("O cep informado é inválido.");
      return;
    }
    if(!radius || (radius.length <= 0 || radius.length > 2)){
      setRadiusError("O raio informado é inválido.");
      return;
    }
    
    console.log('fetchCeps...', zip, radius);
    dispatch(Creators.fetchCeps(zip, radius));
  }

  const handlerExportCSV = async () => {
    
  }

  const columns = [
    { field: "id", headerName: " ", hide:true },
    { field: "cep", headerName: "CEP", width: 150 },
    { field: "address", headerName: "Endereço", width: 700 }
  ];

  const csvHeaders = [
    { label: "ID", key: "id" },
    { label: "CEP", key: "cep" },
    { label: "Endereço", key: "address" }
  ]

  return (
    <Home title={'Buscar CEPs'}>
      {pending ? <ColoredLinearProgress /> : null }
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} md={3}>
            <TextField disabled={pending} variant="outlined" margin="normal" required fullWidth
              value={zip}
              onChange={e => setZip(e.target.value)}
              label="CEP (somente números)"
              autoFocus
              type='number'
              inputProps={{
                maxLength: 8
              }}
            />
            { zipError && <InputLabel className={classes.labelError}>{zipError}</InputLabel>}
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField disabled={pending} variant="outlined" margin="normal" required fullWidth
              value={radius}
              onChange={e => setRadius(e.target.value)}
              label="Raio (km)"
              type='number'
              inputProps={{
                maxLength: 2
              }}
            />
            { radiusError && <InputLabel className={classes.labelError}>{radiusError}</InputLabel>}
          </Grid>
          <Grid item>
            <div style={{height:25}} />
            <Button disabled={pending} variant="contained" color="primary"
              onClick={() => handlerSeek()}>{pending ? 'Buscando...' : 'Buscar'}</Button>
          </Grid>
          { !pending && data?.ceps && data?.ceps.length > 0 && 
            <Grid item>
              <div style={{height:25}} />
              <CSVLink data={data.ceps || []} headers={csvHeaders} className={classes.btnExportCSV} disabled={pending}>
                {pending ? 'Exportando...' : 'Exportar'}
              </CSVLink>
            </Grid>
          }
        </Grid>

        {!pending && error ? 
          <div style={{padding:20}}>
            <Alert severity="error">{error?.message}</Alert>
          </div>
          : null 
        }

      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DataGrid rows={data.ceps || []} columns={columns} density="compact"
              style={{minHeight: '70vh'}}
              autoPageSize={true} 
              disableColumnMenu={false} 
              hideFooterSelectedRowCount={false} />
          </Grid>
        </Grid>
      </Paper>
    </Home>
  );
}

export default Index