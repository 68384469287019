import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import { 
  Box, Card, Button, Container, CssBaseline, Grid,
  TextField, Typography, FormControl, FormLabel,
  Link
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'pages/components/Copyright';
import Logo from 'assets/logo-white.png';
import VenuxxBar from 'assets/venuxx-bar.jpg';
import TrackingWomen from 'assets/tracking-women.png';

// Actions
import { Creators } from 'store/ducks/tracking';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Index = (props, context) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // const [taskStatus, setTaskStatus] = useState(-1);
  // const [jobStatus, setJobStatus] = useState('');
  const [jobMessage, setJobMessage] = useState('');
  const [creationDatetime, setCreationDatetime] = useState('');
  
  const [isCreated, setIsCreated] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isArrived, setIsArrived] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  // const [isCanceled, setIsCanceled] = useState(false);
  // const [isDelivery, setIsDelivery] = useState(false);

  const { pending, error, data } = useSelector(state => state.tracking);
  const {
    // job_pickup_name,
    // job_pickup_phone,
    // job_delivery_datetime,
    // job_pickup_latitude,
    // job_pickup_longitude,
    total_distance_travelled,
    // job_pickup_address,
    // started_datetime,
    // completed_datetime,
    // job_status,
    // is_active,
    // job_type,
    // custom_field,
    task_history,
    tracking_link,
    isCanceled,
    isDelivery
  } = data || {}
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    if(task_history && task_history.length > 0) {
      const arrHistories = task_history.filter(d => String(d.description).toLowerCase().indexOf("status") > -1);
      const nodeStatus = arrHistories[arrHistories.length-1]; //task_history.find(d => String(d.description).toLowerCase().indexOf("status") > -1);
      const statusDscription = nodeStatus?.description;
      const status = statusDscription ? JSON.parse(statusDscription).input : "";
      
      const _isSuccessful = String(status).toLowerCase().indexOf("sucesso") > -1;
      setIsSuccessful(_isSuccessful); // verify if success or error on delivery
      const _isFailure = String(status).toLowerCase().indexOf("falha") > -1;
      setIsFailure(_isFailure); // verify if failure or error on delivery
      setCreationDatetime(nodeStatus?.creation_datetime);

      const arrMotives = task_history.filter(d => String(d.description).toLowerCase().indexOf("motivo") > -1)
      const arrReasons = task_history.filter(d => String(d.description).toLowerCase().indexOf("responsável") > -1)
      
      setIsFailure((arrMotives && arrMotives?.length > 0));

      const nodeMessage = (_isFailure && !_isSuccessful) ? arrMotives[arrMotives.length -1] : arrReasons[arrReasons.length -1];

      const messageDescription = (nodeMessage && nodeMessage?.description) ? nodeMessage?.description : null;
      const message = messageDescription ? JSON.parse(messageDescription).input : "não informado";
      const title = (_isFailure && !_isSuccessful) ? "Motivo" : "Responsável";
      setJobMessage(`${title}: ${message}`);

      task_history.forEach(el => {
        if (String(el.label_description).toLowerCase().indexOf("created") > -1) setIsCreated(true);
        if (String(el.label_description).toLowerCase().indexOf("accepted") > -1) setIsAccepted(true);
        if (String(el.label_description).toLowerCase().indexOf("started") > -1) setIsStarted(true);
        if (String(el.label_description).toLowerCase().indexOf("arrived") > -1) setIsArrived(true);
        // if (String(el.label_description).toLowerCase().indexOf("success") > -1) setIsFailure(true);
        // if (String(el.label_description).toLowerCase().indexOf("successful") > -1) setIsSuccessful(true);
      });
    }
  },[task_history]);

  const handleSubmit = () => {
    if(error) {
      dispatch(Creators.cleanTracking());
      return false
    } else {
      if(orderId.trim().length > 0)
        dispatch(Creators.fetchTracking(orderId));
    }
  };

  const handleNewTracking = () => {
    dispatch(Creators.cleanTracking());
  };

  const getTracking = (status) => [...task_history].find(i => String(i.label_description).toLowerCase().indexOf(status) > -1)

  const line = (label, text, highlight=false) => (
    <Grid container spacing={3}>
      <Grid item xs={4} sm={4} style={{textAlign: 'right'}}>
        <FormLabel>{label}</FormLabel>
      </Grid>
      <Grid item xs={8} sm={8} style={{textAlign:'right'}}>
        <span style={highlight ? {color:'blue', fontSize:'22px'} : {fontSize:'22px'}}>
          {text}
        </span>
      </Grid>
    </Grid>
  )

  const lineTracking = (label, text, description, show=false, highlight=false) => (
    show ?
    <Grid container spacing={3}>
      <Grid item xs={3} sm={2} style={{textAlign: 'right', borderRightWidth:'2px', borderRightColor:highlight?'green':'red', borderRightStyle:'solid'}}>
        <div>
          <div style={{width:'10px', height:'10px', borderRadius:'5px', backgroundColor:highlight?'green':'red', position:'relative', right:'-112%'}} />
        </div>
        <FormLabel style={highlight ? {color:'green'} : {color:'red'}}>{label}</FormLabel>
      </Grid>
      <Grid item xs={8} sm={10}>
        <div style={highlight ? {color:'green'} : {color:'red'}}>
          {text}
        </div>
        <div style={highlight ? {color:'green'} : {color:'red'}}>
          {description}
        </div>
      </Grid>
    </Grid>
    : null
  )

  return (
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <div className={classes.paper}>

        <Container style={{paddingTop:'10px'}} maxWidth="sm">
          <div style={{backgroundImage:`url(${VenuxxBar})` , width:'100%', height:'50px', backgroundRepeat:'no-repeat', backgroundPosition:'right', backgroundSize:'cover'}} />

          {!data && 
            <div className={classes.form} noValidate>
              <form onSubmit={handleSubmit}>
                
                { !error &&
                  <FormControl style={{width:'100%'}}>
                    <TextField disabled={pending}
                      value={orderId}
                      onChange={e => setOrderId(e.target.value)}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="orderId"
                      label="Número do Rastreio"
                      name="orderId"
                      autoFocus
                    />
                  </FormControl>
                }

                <Button disabled={pending} style={{backgroundColor:'#2f0145', color:'#FFFFFF'}}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  {pending ? 'rastreando...' : error ? 'RASTREAR OUTRO' : 'R A S T R E A R'}
                </Button>
              </form>

              {
                error &&
                <div style={{padding:'10px', backgroundColor: 'antiquewhite', marginTop: '20px', border: 'dashed', borderColor:'#999999', borderWidth:'1px', borderRadius: '5px', paddingLeft: '30px', paddingRight: '30px'}}>
                  <Typography style={{color: '#2f0145', textAlign:'center'}}>
                    O número de rastreio "{orderId}" não foi encontrado.
                  </Typography>
                </div>
              }
            </div>
          }
          {data &&
            <>
              <div style={{color: '#333', padding: '5px', marginBottom: '30px', marginTop:'10px'}}>
                <div style={{ margin:'auto', width:'50%', padding:'10px'}}>
                  <img src={TrackingWomen} style={{width: '100%'}} />
                </div>
                <Card style={{padding:'10px'}}>
                  {line('Número do rastreio', orderId)}
                  <hr />
                  
                  {lineTracking(moment(getTracking('created')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), `Recebemos a solicitação de ${!!total_distance_travelled ? 'entrega' : 'coleta'} do seu pedido`, '', isCreated, isCreated)}
                  {lineTracking(moment(getTracking('accepted')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), 'Seu pedido foi atribuído para uma de nossas especialistas', '', isAccepted, isAccepted)}
                  {lineTracking(moment(getTracking('started')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), `Seu pedido está em rota de ${!!total_distance_travelled ? 'entrega' : 'coleta'}`, '', isStarted, isStarted)}
                  {lineTracking(moment(getTracking('arrived')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), `Nossa especialista chegou no local de ${!!total_distance_travelled ? 'entrega' : 'coleta'}`, '', isArrived, isArrived)}
                  
                  { (isFailure)
                    ? lineTracking(moment(getTracking('arrived')?.creation_datetime).add(7, 'minutes').tz("America/Sao_Paulo").format("DD/MM HH:mm"), `Falha na ${!!total_distance_travelled ? 'entrega' : 'coleta'}`, "", true, false)
                    : null
                  }

                  { (isSuccessful)
                    ? lineTracking(moment(creationDatetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), "Sucesso na entrega", "", true, true)
                    : null
                  }
                  

                  {/* { (isSuccessful && String(jobStatus).toLowerCase() === "sucesso na entrega")
                     ? lineTracking(moment(getTracking('successful')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), 'Seu pedido foi entregue com sucesso', isSuccessful)
                     : (isSuccessful && String(jobStatus).toLowerCase() === "falha na entrega")
                      ? lineTracking(moment(getTracking('successful')?.creation_datetime).tz("America/Sao_Paulo").format("DD/MM HH:mm"), 'Falha na tentativa de entrega', isSuccessful)
                      : null
                  } */}

                  { (isSuccessful || isFailure) &&
                    <div style={{textAlign:'center', backgroundColor:'lightcyan', border:'1px solid cyan', borderRadius:'5px', fontSize:'large', fontWeight:'bold', color:'darkviolet'}}>
                      <p>
                        {jobMessage}
                      </p>
                    </div>
                  }

                  {
                    (isStarted && !isArrived && !isSuccessful) && 
                    <div style={{padding:'10px', textAlign:'center'}}>
                      Clique aqui e acompanhe sua entrega <Link href={tracking_link} target="_blank">{tracking_link}</Link>
                    </div>
                  }
                </Card>

                { ((isCanceled && isDelivery) && !isSuccessful) ?
                    <div style={{padding:'10px', backgroundColor: 'red', marginTop: '20px', border: 'dashed', borderColor:'#999999', borderWidth:'1px', borderRadius: '5px', paddingLeft: '30px', paddingRight: '30px'}}>
                      <Typography style={{color: '#FFFFFF', fontWeight: 'bold', textAlign:'center'}}>
                        Entrega Cancelada
                      </Typography>
                    </div>
                  : ((isCanceled && !isDelivery) && !isSuccessful) ?
                    <div style={{padding:'10px', backgroundColor: 'red', marginTop: '20px', border: 'dashed', borderColor:'#999999', borderWidth:'1px', borderRadius: '5px', paddingLeft: '30px', paddingRight: '30px'}}>
                      <Typography style={{color: '#FFFFFF', fontWeight: 'bold', textAlign:'center'}}>
                        Coleta Cancelada
                      </Typography>
                    </div>
                  : null
                }

                <div style={{padding:'10px', backgroundColor: 'antiquewhite', marginTop: '20px', border: 'dashed', borderColor:'#999999', borderWidth:'1px', borderRadius: '5px', paddingLeft: '30px', paddingRight: '30px'}}>
                  <Typography style={{color: '#2f0145', textAlign:'center'}}>
                    Caso as informações não tenha atendido suas expectativas, <Link href={'https://api.whatsapp.com/send?phone=5511981483343'} target="_blank" style={{cursor:'pointer'}}>clique aqui</Link> [+55 (11) 98148-3343] e fale com nossa central de relacionamento
                  </Typography>
                </div>
              </div>

              <Button disabled={pending} style={{backgroundColor:'#2f0145', color:'#FFFFFF'}}
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleNewTracking}
              >
                Rastrear outro pedido
              </Button>
            </>
          }
        </Container>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Index
