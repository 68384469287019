import React, { useState } from 'react';
import { DataGrid} from "@material-ui/data-grid";
import { CircularProgress } from '@material-ui/core';

const DataGridStores = ({data}) => {
  // Estado para armazenar os IDs das linhas expandidas
  const [expandedRows, setExpandedRows] = useState([]);

  // Função para alternar a expansão de uma linha
  const toggleRow = (id) => {
    setExpandedRows(prev => (
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    ));
  };

  // Dados principais (origin_name, count, status)
  const getRows = () => {
    const items = [...data].map((row, idx) => (
      {
        id: idx,
        origin_name: row?.origin_name,
        count: row?.count,
        status: row?.status,
        message: row?.message,
        orders: row?.orders,
        pickupTemplate: row?.pickupTemplate,
        deliveryTemplate: row?.deliveryTemplate,
      }
    ));
    
    return items;
  }

  // Colunas para o DataGrid principal
  const columns = [
    { field: 'origin_name', headerName: 'Loja', width: 400 },
    { field: 'count', headerName: 'Ordens', width: 150 },
    { field: 'status', headerName: 'Status', width: 200,
      renderCell: (param) => (
        param?.value === 0
        ? <span style={{fontWeight:'bold'}}>Não Processado</span>
        : param?.value === 1 ?
        <span style={{color:'blue', fontWeight:'bold'}}> <CircularProgress size={15} /> Processando...</span>
        :[200, 201, 202].indexOf(param?.value) > -1
        ? <span style={{color:'green', fontWeight:'bold'}}>Processado</span>
        : <span style={{color:'red', fontWeight:'bold'}}>Ocorreu algum erro!</span>
      )
     },
     { field: 'message', headerName: 'Mensagem', width: 350 },
  ];
  return (
    <DataGrid density="compact"
      rows={getRows()}
      columns={columns}
      pageSize={100}
      rowsPerPageOptions={[100]}
      checkboxSelection={false}
      disableSelectionOnClick
    />
  );
};

export default DataGridStores;
