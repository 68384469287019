import { createActions, createReducer } from "reduxsauce";
/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchCeps: ['zip', 'radius'],
  fetchCepsSuccess: ['data'],
  fetchCepsFailure: ['error'],
  clearCeps: []
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: [],
  error: null
}
const INITIAL_STATE = STATE;

// ceps
const fetchCeps = state => {
  return {...state, pending: true, error: null};
};

const fetchCepsSuccess = (state, { data }) => {
  return {...state, data, pending: false, error: null};
};

const fetchCepsFailure = (state, { error }) => {
  return {...state, pending: false, error };
};

const clearCeps = state => {
  return {...state, pending: false, data:[], error: null};
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CEPS]: fetchCeps,
  [Types.FETCH_CEPS_SUCCESS]: fetchCepsSuccess,
  [Types.FETCH_CEPS_FAILURE]: fetchCepsFailure,
  [Types.CLEAR_CEPS]: clearCeps,
});