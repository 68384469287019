import { Typography, IconButton } from '@material-ui/core';
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from '@material-ui/core/Box'

const CustomPagination = ({
  currentPage,
  totalPages,
  pageSize,
  onFirstPage,
  onPrevPage,
  onNextPage,
  onLastPage,
  totalRecords,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="8px">
      {/* Botões de Paginação */}
      <Box>
        <IconButton onClick={onFirstPage} disabled={currentPage === 1}>
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={onPrevPage} disabled={currentPage === 1}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="body2" component="span" style={{ margin: "0 16px" }}>
          Página {currentPage} de {totalPages}
        </Typography>
        <IconButton onClick={onNextPage} disabled={currentPage === totalPages}>
          <ChevronRightIcon />
        </IconButton>
        <IconButton onClick={onLastPage} disabled={currentPage === totalPages}>
          <LastPageIcon />
        </IconButton>
      </Box>

      {/* Informações de Registros */}
      <Typography variant="body2">
        {`Registros: ${currentPage === 1 ? 1 : ((pageSize+1) * (currentPage-1))} de ${totalRecords}`}
      </Typography>
    </Box>
  );
};

export default CustomPagination;